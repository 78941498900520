import React, { } from 'react';
import { Button, Input, message, Space, TableProps } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import {
    updateKlarityResourceStatus,
} from 'api/operation';
import s from './s.module.less';
import { SearchOutlined } from '@ant-design/icons';
import { EOriderType } from 'types/operation';
import { Meta } from 'types/common';
import { useNavigate } from 'react-router-dom';
import { TResource } from 'types/common';
import CreateAndEditResourceModal from './components/CreateAndEditResourceModal';
import useKlarityResource from 'hooks/useKlarityResource';
import { RESOURCE_CATEGORY } from 'constants/common';
import dayjs from 'dayjs';

type Props = {

};

const HelloKlarityProviderResourcePage = (props: Props) => {
    const navigation = useNavigate();
    const [itemList, loading, refetch] = useKlarityResource(true);
    const [total, setTotal] = React.useState<number>(0);
    const [current, setCurrent] = React.useState<TResource>();
    const [showModal, setShowModal] = React.useState(false);
    const [updatingId, setUpdatingId] = React.useState<number>();

    const pageSize = 10;

    const statusOptions: Meta[] = [
        {
            label: 'Published',
            value: 'Published',
        },
        {
            label: 'Unpublished',
            value: 'Unpublished',
        },
    ];
    const categoryOptions: Meta[] = [...RESOURCE_CATEGORY];

    const [page, setPage] = React.useState(1);
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState<string>('');
    const [statusFilter, setStatusFilter] = React.useState<('Published' | 'Unpublished')[]>();
    const [categoryFilter, setCategoryFilter] = React.useState<string[]>();
    const [orderType, setOrderType] = React.useState<EOriderType>();

    // React.useEffect(() => {
    //     //todo remove
    //     if (itemList && itemList.length) {
    //         setCurrent(itemList[0]);
    //         setShowModal(true);
    //     }
    // }, [itemList]);

    const onTableChange: TableProps<TResource>['onChange'] = (pagination, filters: any, sorter?: any, action?: any) => {
        //filter change
        const pageInx = pagination?.current || 1;
        const _statusFilter = filters.status || [];
        const _categoryFilter = filters.category || [];
        let _orderType;
        if (action?.action === 'sort') {
            _orderType = sorter.order === 'ascend' ? EOriderType.ASC : EOriderType.DESC;
        }
        setOrderType(_orderType);
        setPage(pageInx);
        setStatusFilter(_statusFilter);
        setCategoryFilter(_categoryFilter);
    };

    const displayItem = itemList?.filter((item) => {
        let ret = true;
        if (nameFilter && ret) {
            ret = item.title?.toLowerCase().includes(nameFilter.toLowerCase());
        }
        if (statusFilter?.length && ret) {
            ret = !!(item.status && statusFilter.includes(item.status));
        }
        if (categoryFilter?.length && ret) {
            ret = !!(item.category && categoryFilter.includes(item.category));
        }
        return ret;
    });
    if (orderType === EOriderType.ASC) {
        displayItem.sort((a, b) => {
            if (!a.updateAt) {
                return 1;
            }
            if (!b.updateAt) {
                return -1;
            }
            return dayjs(a.updateAt).isBefore(b.updateAt) ? -1 : 1;
        });
    } else if (orderType === EOriderType.DESC) {
        displayItem.sort((a, b) => {
            if (!a.updateAt) {
                return 1;
            }
            if (!b.updateAt) {
                return -1;
            }
            return dayjs(a.updateAt).isBefore(b.updateAt) ? 1 : -1;
        });
    }

    const updateStatus = async (id: number, status: 'Published' | 'Unpublished') => {
        setUpdatingId(id);
        const res = await updateKlarityResourceStatus(id, status);
        if (!res.error) {
            message.success('Update status successfully');
            refetch();
        }
        setUpdatingId(undefined);
    }

    const columns: ColumnsType<TResource> = [
        {
            title: 'RESOURCE TITLE',
            dataIndex: 'title',
            key: 'title',
            width: '300px',
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingNameFilter}
                        onChange={
                            (e) => {
                                setPendingNameFilter(e.target.value);
                            }
                        }
                        onPressEnter={() => {
                            setNameFilter(pendingNameFilter);
                            setPage(1);
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                                setPage(1);
                                close();
                            }
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                                setPage(1);
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (name: string, item: TResource) => {
                return (
                    <div className={s.cell}>
                        <div className={s.name}>
                            {name}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            width: '140px',
            filters: statusOptions?.map((stateItem) => ({
                text: stateItem.label,
                value: stateItem.value as string,
            })) || [],
            render: (val: string) => {
                const className = val === 'Published' ? s.published : s.unpublished;
                return (
                    <div className={`${s.cell} ${className}`}>
                        {val}
                    </div>
                );
            },
        },
        {
            title: 'CATEGORY',
            dataIndex: 'category',
            width: '220px',
            filters: categoryOptions?.map((stateItem) => ({
                text: stateItem.label,
                value: stateItem.value as string,
            })) || [],
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {val}
                    </div>
                );
            },
        },
        {
            title: 'LAST MODIFIED',
            dataIndex: 'updateAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '200px',
            className: s.tableColumn,
            render: (time: string) => {
                return <div>{time ? dayjs(time).format('MMMM DD, YYYY') : 'Nil'}</div>;
            },
        },
        {
            title: '',
            fixed: 'right',
            render: (p: TResource) => {
                return (
                    <div className={s.actionWrap}>
                        {
                            p.status === 'Published' &&
                            <Button
                                className={s.link}
                                onClick={() => {
                                    updateStatus(p.id!, 'Unpublished');
                                }}
                                loading={updatingId === p.id}
                                type="text"
                            >
                                Unpublish
                            </Button>
                        }
                        {
                            p.status === 'Unpublished' &&
                            <Button
                                className={s.link}
                                onClick={() => {
                                    updateStatus(p.id!, 'Published');
                                }}
                                loading={updatingId === p.id}
                                type="text"
                            >
                                Publish
                            </Button>
                        }
                        <Button
                            className={s.link}
                            onClick={() => {
                                setCurrent(p);
                                setShowModal(true);
                            }}
                            type="text"
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ].filter(Boolean) as ColumnsType<TResource>;

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Provider resources</div>
                <Button
                    onClick={() => {
                        setCurrent(undefined);
                        setShowModal(true);
                    }}
                >
                    + Add a new resource
                </Button>
            </div>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: 'max-content' }}
                loading={loading}
                columns={columns}
                onChange={onTableChange}
                data={displayItem || []}
            />
            {
                showModal &&
                <CreateAndEditResourceModal
                    item={current}
                    isOps
                    onCancel={() => {
                        setShowModal(false);
                    }}
                    onSuccess={() => {
                        setShowModal(false);
                        refetch();
                    }}
                />
            }

        </div>
    );
};

export default HelloKlarityProviderResourcePage;
