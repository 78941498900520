import { IProfileDataSaveInput } from './operation';
import { ProviderPractice } from './provider';
import { IEditBookingLink } from 'pages/operation/CustomizeHelloKlarityPPP/type';

export enum EType {
    HELLOW_KLARITY = 'hk',
    AIRTABLE = 'at',
}

export enum FormFields {
    ID = 'id',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    MIDDLE_NAME = 'middleName',
    NPI = 'npi',
    LANGUAGE = 'language',
    // ADDRESS = 'address',
    CITY = 'city',
    ZIP_CODE = 'zip',
    STATE = 'state',
    LICENSES = 'licenses',
    INSURANCE_LIST = 'insuranceList',
    OTHER_INSURANCE_LIST = 'otherInsuranceList',
    CHANNELS = 'channels',
    INTRO = 'intro',
    YEAR_EXP = 'yearExp',
    TITLE = 'title',
    PHOTO = 'photo',
    TREATMENT_PHILOSOPHY = 'treatmentPhilosophy',
    SPECIALTIES = 'specialties', // ...
    TREATMENT_APPROACH = 'treatmentApproach',
    SPECIALITYINHEALTH = 'specialityInHealth',
    SPECIALITYINFAMILY = 'specialityInFamily',
    ORGANIZATION_NAME = 'organizationName',
    IS_CURRENTLY_WORK = 'currently',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    EMPLOYMENT_TYPE = 'employmentType',
    HOSPITAL_LOCATION = 'hospitalLocation',
    DESCRIPTION = 'description',
    LOGO = 'experienceLogo',
    SCHOOL_NAME = 'schoolName',
    MAJOR = 'major',
    DEGREE = 'degree',
    CERTIFICATES = 'certificates',
    IS_PURSUING_DEGREE = 'currently',
    SCHOOL_LOGO = 'schoolLogo',
    PHONE = 'tel',
    EMAIL = 'email',
    COLOR_ID = 'colorId',
    DURATION = 'duration',
    NAME = 'name',
    PERSON_ADDRESS = 'personAddress',
    PERSON_CITY = 'personCity',
    PERSON_STATE = 'personState',
    PERSON_TYPE = 'personType',
    PERSON_ZIP = 'personZip',
    PERSON_ID = 'providerId',
    SEND_FORM = 'sendForm',
    TELEHEALTH_TYPE = 'telehealthType',
    TYPE = 'type',
    PRICE = 'price',
    INTAKE_FORM = 'intakeForm',
    PRACTICE_ADDRESS = 'practiceAddress',
    PRACTICE_STATE = 'practiceState',
    PRACTICE_CITY = 'practiceCity',
    PRACTICE_ZIP = 'practiceZip',
    PRACTICE_PHONE = 'practicePhone',
    PRACTICE_LOGO = 'practiceLogo',
    CREDENTIAL = 'credential',
    PROVIDER_PRACTICE = 'providerPractice',
    HEADLINE = 'headLine',
    SPECIALTY_LIST = 'specialtyList',
    CONDITION_TREATED_LIST = 'conditionTreatedList',
    IS_DEFAULT = 'isDefault',
    AS_MAILING = 'asMailing',
    PRACTICE_NAME = 'practiceName',
    PRACTICE_EMAIL = 'practiceEmail',
    PRACTICE_WEBSITE = 'practiceWebsite',
    SUPPORT_PAY_TYPE = 'supportPayType',
    ADDRESS_ID = 'addressId',
    SERVICE_TYPE = 'type',
    ADDRESS_PUBLIC_TYPE = 'publicType',
    APPOINTMENT_LINK = 'appointmentIntegrationUrl',
    USE_INTEGRATION_URL = 'useIntegrationUrl',
    ENABLE_SLIDING_SCALE = 'enableSlidingScale',
    LOWEST_PRICE = 'lowestPrice',
    HIGHEST_PRICE = 'highestPrice',
}

export interface IBasicInfo {
    [FormFields.ID]: number;
    [FormFields.FIRST_NAME]: string;
    [FormFields.LAST_NAME]: string;
    [FormFields.MIDDLE_NAME]?: string;
    [FormFields.HEADLINE]?: string;
    [FormFields.YEAR_EXP]?: number;
    [FormFields.NPI]?: string;
    [FormFields.INTRO]?: string;
    // [FormFields.CITY]: string;
    // [FormFields.ZIP_CODE]: string;
    // [FormFields.STATE]: string;
    // [FormFields.LICENSES]: Array<License>;
    [FormFields.LANGUAGE]?: string;
    [FormFields.TITLE]?: string;
    [FormFields.CREDENTIAL]?: string[];
    [FormFields.PHOTO]?: string;
    [FormFields.PROVIDER_PRACTICE]?: ProviderPractice,
}

export interface ISpecialtiesApproaches {
    [FormFields.SPECIALTY_LIST]: string[];
    [FormFields.CONDITION_TREATED_LIST]: string[];
}

export enum EKlarityPageStatus {
    ACTIVE = 'A',
    INACTIVATE = 'D',
}

export enum EKlarityPageStatusLabel {
    A = 'Active',
    D = 'Inactive',
}

export enum EKlarityPageStatusActionLabel {
    A = 'Active',
    D = 'Inactivate',
}

export type TPage = {
    id?: number;
    name: string;
    fullName: string;
    createBy?: number;
    createTime?: string;
    path?: string;
    status: EKlarityPageStatus
    type: string;
    updateBy?: number;
    updateTime?: string;
};

export enum ECarouselsStatusLabel {
    A = 'Active',
    D = 'Inactive',
}

export enum ECarouselsStatusActionLabel {
    A = 'Active',
    D = 'Inactivate',
}

export enum ECarouselsStatus {
    ACTIVE = 'A',
    INACTIVATE = 'D',
}

export type TCarousel = {
    status: ECarouselsStatus;
    id?: number | null;
    title: string;
    description: string;
    actionName: string;
    actionLink: string;
    image: string;
    lastUpdateAt?: string;
    createAt?: string;
    priority?: number
    pageId?: number | string;
};

export enum EPageStatus {
    ADD = 'add',
    EDIT = 'edit',
    DUPLICATE = 'duplicate',
}

export type TCondition = {
    id: number;
    name: string;
};

export type TConditionSetting = {
    conditionName: string;
    createAt?: string;
    createBy?: number;
    id?: number;
    lastUpdateAt?: string;
    lastUpdateBy?: string;
    logo: string;
    priority: number;
    status?: 'A' | 'D';
    subtext: string;
    type?: string;
    serviceSpecialtyName: string;
    serviceSpecialtyPriority: number;

};

export type TStat = {
    'A': number;
    'D': number;
};

export type TPageParams = {
    pageId: number | string;
    type: string;
};

export type TQuestion = {
    question: string;
    answer: string;
    ID: string;
    index: string;
    type: string[];
    update_time: string;
    pre_type: string;
    path_name: string;
};

export interface IDraftStorage {
    bookingListData: IEditBookingLink[];
    specialtiesHighlights: string;
    profileData: IProfileDataSaveInput;
}
export type TPageManagement = {
    pageId: number | string;
    carousels: TCarousel[];
    conditions: TConditionSetting[]
};

export type TObjectAction = {
    type: string;
    params?: TCarousel;
    condition?: TConditionSetting;
    id?: string | number;
    fromId?: string | number;
    toId?: string | number;
};

export enum EKlarityPage {
    HOME = 'HOME',
    CONDITION = 'CONDITION',
    STATE = 'STATE',
    FAQS = 'FAQS',
}

export type TKlarityPage = {
    id: number;
    name: string, //'ADHD',
    path?: string,
    createAt: string, //'2024-01-29T02:30:43.000+00:00',
    lastUpdateAt: string, // '2024-04-20T12:20:12.000+00:00',
    createBy: number,
    lastUpdateBy: number,
    state: string;
    status: EKlarityPageStatus;
    type: EKlarityPage;
};

export enum SPECIALTY_CONDITION_KEYS {
    COUNSELI_THERAPY = 'Counseling & Therapy',
    DERMATOLOGY = 'Dermatology',
    INFECTIONS = 'Infections',
    MEN_HEALTH = 'men\'s Health',
    PREVENTIVE_PRIMARY_CARE = 'Preventive & Primary Care',
    PSYCHIATRIC_MENTAL_HEALTH = 'Psychiatric & Mental Health',
    WEIGHT_LOSS = 'Weight Loss',
    WOMEN_HEALTH = 'Women\'s Health',
}

export interface ISpecialtyCondition {
    [SPECIALTY_CONDITION_KEYS.COUNSELI_THERAPY]: string[];
    [SPECIALTY_CONDITION_KEYS.DERMATOLOGY]: string[];
    [SPECIALTY_CONDITION_KEYS.INFECTIONS]: string[];
    [SPECIALTY_CONDITION_KEYS.MEN_HEALTH]: string[];
    [SPECIALTY_CONDITION_KEYS.PREVENTIVE_PRIMARY_CARE]: string[];
    [SPECIALTY_CONDITION_KEYS.PSYCHIATRIC_MENTAL_HEALTH]: string[];
    [SPECIALTY_CONDITION_KEYS.WEIGHT_LOSS]: string[];
    [SPECIALTY_CONDITION_KEYS.WOMEN_HEALTH]: string[];
}
export interface ISpecialtyNServiceTypeResponse {
    serviceSpecialtyList: string[];
    serviceTypeList: string[];
    specialtyCondition: ISpecialtyCondition;
}

export interface IBatchUpdateBookingLinkInput {
    bpProviderId: number;
    directBookingLink: string;
    serviceType: string;
    state: string;
    specialty: string;
    conditions: string;
    allCondition: boolean;
}

export enum EBookingLinkModalMode {
    LINK_SELECT = 'LINK_SELECT',
    LINK_EDIT = 'LINK_EDIT',
    LINK_ADD = 'LINK_ADD',
}

export interface IBookingLinkTableData {
    state: string,
    specialty: string,
    serviceType: string,
    bookingLinkName: string,
}

export type TServiceSetting = {
    createAt?: string;
    createBy?: number;
    id?: number;
    image: string;
    lastUpdateAt?: string;
    lastUpdateBy?: string | number;
    priority?: number;
    status?: 'A' | 'D';
    subtext: string;
    type?: string;
    servicePage: string;
    pageId: string | number;
};

export type TMedicationSetting = {
    id?: number;
    pageId: string | number;
    subtext: string;
    medicationPageUrl: string,
    imageUrl: string,
    tag: string,
    seq: number,
};

export interface IGetSpecificConditionDetailInput {
    providerId?: number;
    condition?: string;
}

export interface IGetSpecificConditionDetailResponse {
    id?: number;
    providerId?: number;
    conditionName?: string;
    headLines?: string;
    aboutMe?: string;
    tags?: string[];
    prescribableMedications?: string[];
    nonPrescribableMedications?: string[];
    specialty?: string[];
    specialtyHighlight?: string[];
    conditionTreated?: string[];
    createAt?: string;
    createBy?: string;
    updateAt?: string;
    updateBy?: string;
}
