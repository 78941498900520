import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spin, Tabs, Button, message } from 'antd';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import { useNavigate, useParams } from 'react-router-dom';
import CarouselsManagement from '../components/CarouselsManagement';
import ConditionManagement from '../components/ConditionManagement';
import ServiceManagement from '../components/ServiceManagement';
import MedicationManagement from '../components/MedicationManagement';
import CarouselsPreviewEmpty from '../components/CarouselsPreviewEmpty';
import CarouselsPreview from '../components/CarouselsPreview';
import ConditionPreview from '../components/ConditionPreview';
import FaqPreview from '../components/FaqPreview';
import ServicePreview from '../components/ServicePreview';
import ProviderManagement from '../components/ProviderManagement';
import ProviderPreview from '../components/ProviderPreview';

import { PATH } from 'constants/path';
import s from './s.module.less';
import {
    EKlarityPage,
    TCarousel,
    TConditionSetting,
    TMedicationSetting,
    TObjectAction,
    TPageManagement,
    TServiceSetting,
} from 'types/helloKlarity';
import {
    klarityGoLiveUpdate,
    getKlarityCarouselsList,
    getKlarityConditionList,
    klarityGetUpdateTime,
} from 'api/operation';
import dayjs from 'dayjs';
import ContentManagement from '../components/ContentManagement';
import FaqManagement from '../components/FaqManagement';
import InternalLinkManagement from '../components/InternalLinkManagement';

import { ServerMeta } from 'types/common';
import useLoadBasicData from 'hooks/useLoadBasicData';
import ContentPreview from '../components/ContentPreview';
import { TContentInfo, TFaqInfo, TInternalLink } from 'types/operation';
import InternalLinkPreview from '../components/InternalLinkPreview';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { getHelloKlarityHost, getUrlParam } from 'utils/common';
import MedicationPreview from '../components/MedicationPreview';

const Detail: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [allState] = useGetSettingData(EnumFields.STATE);
    const [allSpe] = useGetSettingData(EnumFields.HELLO_KLARITY_SPECIALTY);
    const [allCondition] = useGetSettingData(EnumFields.HELLO_KLARITY_CONDITION);
    const [tabActiveKey, setTabActiveKey] = useState('1');
    const [previewItem, setPreviewItem] = useState<TCarousel | undefined>(undefined);
    const [previewCondition, setPreviewCondition] = useState<string>('');
    const [previewService, setPreviewService] = useState<TServiceSetting[]>(
        [] as TServiceSetting[],
    );

    const [previewMedications, setPreviewMedications] = useState<TMedicationSetting[]>(
        [],
    );

    const [previewContent, setPreviewContent] = useState<
    TContentInfo[]
    >([] as TContentInfo[]);
    const [previewContentEnabled, setPreviewContentEnabled] = useState<boolean>(true);

    const [previewFAQ, setPreviewFAQ] = useState<TFaqInfo[] | undefined>(undefined);
    const [previewInternalLinkSpecialtyDoctor, setPreviewInternalLinkSpecialtyDoctor] = useState<TInternalLink[]>();
    const [previewInternalLinkServicePage, setPreviewInternalLinkServicePage] = useState<TInternalLink[]>();
    const [previewInternalLinkOtherState, setPreviewInternalLinkOtherState] = useState<TInternalLink[]>();

    const navigate = useNavigate();
    let { type } = useParams();
    const { id } = useParams();
    type = type?.toLocaleUpperCase();
    const state = getUrlParam('state');
    const condition = getUrlParam('condition');

    const [updateTime, setUpdateTime] = useState<string>('');
    //const [pageName, setPageName] = useState<string>('');
    const [goLiveObj, setGoLiveObj] = useState<TPageManagement>(
        {} as TPageManagement,
    );

    const targetState = state ? (allState as ServerMeta[])?.find((item) => item.dictKey === state || item.content === state) : undefined;
    const targetCondition = condition ? (allCondition as ServerMeta[])?.find((item) => item.dictKey === condition || item.content === condition) : undefined;
    const targetSpecialty = targetCondition && (allSpe as ServerMeta[])?.find((item) => item.dictKey === targetCondition?.description || item.content === targetCondition?.description);

    let pageName = type === EKlarityPage.HOME ? 'Homepage' : 'Faqs';
    if (targetCondition && targetState) {
        pageName = `${targetCondition?.content} in ${targetState?.content}`;
    } else if (targetState) {
        pageName = targetState?.content;
    }

    const plpRef = useRef<HTMLDivElement>(null);

    const [loadBaiscData] = useLoadBasicData();

    const host = getHelloKlarityHost();

    const handleRefreshConditionList = async () => {
        const { data } = await getKlarityConditionList();
        setGoLiveObj({
            ...goLiveObj,
            conditions: data?.data || [],
        });
    };

    const handlePreview = (item: TCarousel | undefined) => {
        setPreviewItem(item);
    };

    const refreshPLP = () => {
        if (plpRef.current) {
            plpRef.current?.refresh();
        }
    };

    const homeTabItems = [
        {
            key: '1',
            label: <span className={s.tabLabel}>Carousels</span>,
            children: (
                <CarouselsManagement
                    isLoading={isLoading}
                    pageName={pageName}
                    goLiveObj={goLiveObj}
                    onSuccess={(item) => handleAction(item)}
                    onPreview={(item) => handlePreview(item)}
                />
            ),
        },
        {
            key: '2',
            label: <span className={s.tabLabel}>Conditions</span>,
            children: (
                <ConditionManagement
                    isLoading={isLoading}
                    goLiveObj={goLiveObj}
                    onSuccess={(item) => handleAction(item)}
                    onPreview={(name) => setPreviewCondition(name)}
                    onRefreshConditionList={() => handleRefreshConditionList()}
                />
            ),
        },
        {
            key: '6',
            label: <span className={s.tabLabel}>Service</span>,
            children: (
                <ServiceManagement
                    pageId={id}
                    isLoading={isLoading}
                    goLiveObj={goLiveObj}
                    onSuccess={(item) => handleAction(item)}
                    onPreview={(list) => setPreviewService(list)}
                />
            ),
        },
        {
            key: '4',
            label: <span className={s.tabLabel}>FAQ mgmt</span>,
            children: (
                <FaqManagement
                    pageId={id}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={(items: TFaqInfo[]) => {
                        setPreviewFAQ(items);
                    }}
                />
            ),
        },
        {
            key: '7',
            label: <span className={s.tabLabel}>Medications</span>,
            children: (
                <MedicationManagement
                    pageId={id}
                    isLoading={isLoading}
                    onSuccess={(item) => handleAction(item)}
                    onPreview={(list) => setPreviewMedications(list)}
                />
            ),
        },
    ];

    const faqsTabItems = [
        {
            key: '4',
            label: <span className={s.tabLabel}>FAQ mgmt</span>,
            children: (
                <FaqManagement
                    pageId={id}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={(items: TFaqInfo[]) => {
                        setPreviewFAQ(items);
                    }}
                />
            ),
        },
    ];

    const stateTabItems = [
        {
            key: '1',
            label: <span className={s.tabLabel}>Carousels</span>,
            children: (
                <CarouselsManagement
                    isLoading={isLoading}
                    pageName={pageName}
                    goLiveObj={goLiveObj}
                    onSuccess={(item) => handleAction(item)}
                    onPreview={(item) => handlePreview(item)}
                />
            ),
        },
        {
            key: '8',
            label: <span className={s.tabLabel}>Provider mgmt</span>,
            children: (
                <ProviderManagement
                    pageName={pageName}
                    pageId={id}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={() => refreshPLP()}
                />
            ),
        },
        {
            key: '4',
            label: <span className={s.tabLabel}>FAQ mgmt</span>,
            children: (
                <FaqManagement
                    pageId={id}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={(items: TFaqInfo[]) => {
                        setPreviewFAQ(items);
                    }}
                />
            ),
        },
    ];

    const conditionTabItems = [
        {
            key: '1',
            label: <span className={s.tabLabel}>Carousels</span>,
            children: (
                <CarouselsManagement
                    isLoading={isLoading}
                    pageName={pageName}
                    goLiveObj={goLiveObj}
                    onSuccess={(item) => handleAction(item)}
                    onPreview={(item) => handlePreview(item)}
                />
            ),
        },
        {
            key: '8',
            label: <span className={s.tabLabel}>Provider mgmt</span>,
            children: (
                <ProviderManagement
                    pageName={pageName}
                    pageId={id}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={() => refreshPLP()}
                />
            ),
        },
        {
            key: '3',
            label: <span className={s.tabLabel}>Content mgmt</span>,
            children: (
                <ContentManagement
                    pageId={id}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={(items, enabled) => {
                        setPreviewContent(items);
                        setPreviewContentEnabled(enabled);
                    }}
                />
            ),
        },
        {
            key: '4',
            label: <span className={s.tabLabel}>FAQ mgmt</span>,
            children: (
                <FaqManagement
                    pageId={id}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={(items: TFaqInfo[]) => {
                        setPreviewFAQ(items);
                    }}
                />
            ),
        },
        {
            key: '5',
            label: <span className={s.tabLabel}>Internal links</span>,
            children: (
                <InternalLinkManagement
                    host={host}
                    specialty={targetSpecialty as ServerMeta}
                    pageId={id ? parseInt(id, 10) : 0}
                    state={targetState}
                    condition={targetCondition}
                    onPreview={(items1, item2, item3) => {
                        setPreviewInternalLinkSpecialtyDoctor(items1);
                        setPreviewInternalLinkServicePage(item2);
                        setPreviewInternalLinkOtherState(item3);
                    }}
                />
            ),
        },
    ];

    const handleTabClick = useCallback((key: string) => {
        setTabActiveKey(key);
    }, []);

    const handleExit = useCallback(() => {
        navigate(`${PATH.OPERATION}/${PATH.HELLO_KLARITY_PAGE_MANAGEMENT}`, { replace: true });
    }, [navigate]);

    const getInitObject = async () => {
        setIsLoading(true);
        let conditionList = {
            data: [],
        };
        const { data: carouselList } = await getKlarityCarouselsList({
            pageId: id ?? '',
        });
        if (type === EKlarityPage.HOME) {
            const { data } = await getKlarityConditionList();
            conditionList = data;
        }
        setGoLiveObj({
            pageId: id ?? '',
            carousels: (carouselList?.data || []).map((item: TCarousel, index: number) => {
                delete item.lastUpdateAt;
                delete item.createAt;
                item.priority = index;
                return item;
            }),
            conditions: (conditionList?.data || []).map((item: TConditionSetting) => {
                delete item.lastUpdateAt;
                delete item.createAt;
                return item;
            }),
        });
        setIsLoading(false);
    };

    const fetchData = async () => {
        try {
            const { data, error } = await klarityGetUpdateTime(id!);
            if (!error) {
                const lastUpdateTime = `${dayjs(data.data).format(
                    'MMMM D, YYYY',
                )} at ${dayjs(data.data).format('h:mm A')}`;
                setUpdateTime(lastUpdateTime);
            }
        } catch (e) { }
    };

    const handleGoLive = async () => {
        const obj = goLiveObj.carousels.map((item) => {
            if (`${item.id}`.length > 10) {
                return {
                    ...item,
                    id: null,
                };
            }
            return item;
        });
        try {
            const { error } = await klarityGoLiveUpdate({
                carousels: obj,
                conditions: goLiveObj.conditions,
                pageId: goLiveObj.pageId,
            });
            if (!error) {
                message.success('Changes published successfully');
                fetchData();
                getInitObject();
            } else {
                message.error(error);
            }
        } catch (e) {
            console.log('e: ', e);
        }
    };

    // const getPageInfo = async () => {
    //     try {
    //         const { data, error } = await getKlarityPageById(id!);
    //         if (!error) {
    //             setPageName(data.data?.fullName);
    //         }
    //     } catch (e) {

    //     }
    // };

    const handleAction = (obj: TObjectAction) => {
        let carouselList = goLiveObj.carousels.slice();
        let conditionList = goLiveObj.conditions.slice();
        if (obj.type === 'CAROUSEL_ADD') {
            carouselList.push(obj.params || ({} as TCarousel));
            // to refresh the carousel id
            fetchData();
            getInitObject();
        } else if (obj.type === 'CAROUSEL_EDIT') {
            carouselList = carouselList.map((item) => {
                if (item.id === obj?.params?.id) {
                    return obj.params as TCarousel;
                } else {
                    return item;
                }
            });
        } else if (obj.type === 'CAROUSEL_DELETE') {
            carouselList = carouselList.filter((item) => item.id !== obj.id);
        } else if (obj.type === 'CAROUSEL_EXCHANGE') {
            const fromItem = carouselList.filter(
                (item) => `${item.id}` === `${obj.fromId}`,
            )[0];
            const toItem = carouselList.filter(
                (item) => `${item.id}` === `${obj.toId}`,
            )[0];

            const fromTemp = fromItem.priority;
            const toTemp = toItem.priority;

            carouselList = carouselList
                .map((item) => {
                    if (`${item.id}` === `${obj.fromId}`) {
                        item.priority = toTemp;
                    }
                    if (`${item.id}` === `${obj.toId}`) {
                        item.priority = fromTemp;
                    }
                    return item;
                })
                .sort((a, b) => a.priority || 0 - (b.priority || 0));
        } else if (obj.type === 'CONDITION_EDIT') {
            conditionList = conditionList.map((item) => {
                if (`${item.id}` === `${obj.condition?.id}`) {
                    return obj.condition as TConditionSetting;
                } else {
                    return item;
                }
            });
        }
        setGoLiveObj({
            pageId: goLiveObj.pageId,
            carousels: carouselList,
            conditions: conditionList,
        });
    };

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    useEffect(() => {
        if (type === EKlarityPage.FAQS) {
            setTabActiveKey('4');
        }
    }, [type]);

    useEffect(() => {
        getInitObject();
        fetchData();
        //getPageInfo();
    }, []);

    const renderHeader = (
        <div className={s.titleWrap}>
            <h2 className={s.title}>Edit {pageName}</h2>
            <h2 className={s.time}>Date last modified: {updateTime}</h2>
            <div className={s.info}>
                <div className={s.left}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.25608 3.0976C9.02069 1.7383 10.9778 1.7383 11.7424 3.0976L17.3227 13.0181C18.0726 14.3514 17.1092 15.9987 15.5795 15.9987H4.41893C2.88927 15.9987 1.92585 14.3514 2.67578 13.0181L8.25608 3.0976ZM10.9991 12.9988C10.9991 13.5511 10.5514 13.9988 9.99915 13.9988C9.44686 13.9988 8.99915 13.5511 8.99915 12.9988C8.99915 12.4465 9.44686 11.9988 9.99915 11.9988C10.5514 11.9988 10.9991 12.4465 10.9991 12.9988ZM9.99915 4.99878C9.44686 4.99878 8.99915 5.44649 8.99915 5.99878V8.99878C8.99915 9.55106 9.44686 9.99878 9.99915 9.99878C10.5514 9.99878 10.9991 9.55106 10.9991 8.99878V5.99878C10.9991 5.44649 10.5514 4.99878 9.99915 4.99878Z" fill="#FBBF24" />
                    </svg>
                </div>
                <div className={s.right}>
                    <div className={s.title}>
                        Changes will be immediately saved
                    </div>
                    <div className={s.content}>
                        Please note that any changes you make on this page will be immediately saved and reflected on the website.
                    </div>
                </div>
            </div>
        </div>
    );

    const renderPreview = (
        <div className={s.preview}>
            <div className={s.modeWrap}>
                <span
                    onClick={() => {
                        setIsMobileMode(false);
                    }}
                    className={isMobileMode ? '' : s.active}
                >
                    <Desktop />
                </span>
                <span
                    onClick={() => {
                        setIsMobileMode(true);
                    }}
                    className={isMobileMode ? s.active : ''}
                >
                    <Mobile />
                </span>
            </div>
            <div className={s.previewContent}>
                {tabActiveKey === '1' && !previewItem && (
                    <CarouselsPreviewEmpty isMobileMode={isMobileMode} />
                )}
                {tabActiveKey === '1' && previewItem && (
                    <CarouselsPreview
                        isMobileMode={isMobileMode}
                        previewItem={previewItem}
                        condition={targetCondition?.content}
                        state={targetState?.content}
                    />
                )}
                {tabActiveKey === '2' && (
                    <ConditionPreview
                        goLiveObj={goLiveObj}
                        isMobileMode={isMobileMode}
                        previewName={previewCondition}
                    />
                )}
                {tabActiveKey === '3' && (
                    <ContentPreview
                        isMobileMode={isMobileMode}
                        previewList={previewContent}
                        previewContentEnabled={previewContentEnabled}
                    />
                )}
                {tabActiveKey === '4' && (
                    <FaqPreview
                        isMobileMode={isMobileMode}
                        previewList={previewFAQ}
                        state={targetState}
                        condition={targetCondition}
                    />
                )}
                {tabActiveKey === '5' && (
                    <InternalLinkPreview
                        isMobileMode={isMobileMode}
                        specialtyData={previewInternalLinkSpecialtyDoctor}
                        servicesData={previewInternalLinkServicePage}
                        stateData={previewInternalLinkOtherState}
                        specialty={targetSpecialty}
                        condition={targetCondition}
                    />
                )}
                {tabActiveKey === '6' && (
                    <ServicePreview
                        isMobileMode={isMobileMode}
                        previewList={previewService}
                        state={targetState}
                        condition={targetCondition}
                    />
                )}
                {tabActiveKey === '7' && (
                    <MedicationPreview
                        isMobileMode={isMobileMode}
                        previewList={previewMedications}
                    />
                )}
                {tabActiveKey === '8' && (
                    <ProviderPreview
                        ref={plpRef}
                        isMobileMode={isMobileMode}
                        state={targetState}
                        condition={targetCondition}
                    />
                )}
            </div>
        </div>
    );

    const renderFooter = (
        <div className={s.footer}>
            <Button type="primary" ghost onClick={handleExit}>
                Exit
            </Button>
            {/* <Button type="primary" onClick={handleGoLive}>
                Go live
            </Button> */}
        </div>
    );

    return (
        <div className={s.wrap}>
            <Spin spinning={isLoading}>
                <div className={s.content}>
                    <div className={s.setting}>
                        {renderHeader}
                        {type === EKlarityPage.HOME && (
                            <Tabs
                                defaultActiveKey="1"
                                activeKey={tabActiveKey}
                                items={homeTabItems as any}
                                onTabClick={handleTabClick}
                            />
                        )}
                        {type === EKlarityPage.FAQS && (
                            <Tabs
                                defaultActiveKey="4"
                                activeKey={tabActiveKey}
                                items={faqsTabItems as any}
                                onTabClick={handleTabClick}
                            />
                        )
                        }
                        {type === EKlarityPage.CONDITION && (
                            <Tabs
                                defaultActiveKey="3"
                                activeKey={tabActiveKey}
                                items={conditionTabItems as any}
                                onTabClick={handleTabClick}
                            />
                        )
                        }
                        {type === EKlarityPage.STATE && (
                            <Tabs
                                defaultActiveKey="3"
                                activeKey={tabActiveKey}
                                items={stateTabItems as any}
                                onTabClick={handleTabClick}
                            />
                        )
                        }
                    </div>
                    {renderPreview}
                </div>
                {renderFooter}
            </Spin>
        </div>
    );
};

export default Detail;
