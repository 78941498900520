import React, { forwardRef, useImperativeHandle } from 'react';
import s from './s.module.less';
import { ServerMeta } from 'types/common';
import { getHelloKlarityHost } from 'utils/common';

type IProps = {
    isMobileMode: boolean;
    state?:ServerMeta;
    condition?: ServerMeta;
};

const type = {
    refresh: 'refresh',
};

const ProviderPreview = forwardRef((props: IProps, ref) => {
    const { isMobileMode, state, condition } = props;
    const [loading, setLoading] = React.useState(true);
    const [currentLink, setCurrentLink] = React.useState('');

    const sendRefresh = () => {
        try {
            const plpIframe: any = document.getElementById('plp-preview');
            const iframeWin = plpIframe.contentWindow;
            const message = {
                type: type.refresh,
            };
            iframeWin.postMessage(message, '*');
        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        if (state && !condition) {
            setCurrentLink(
                `${getHelloKlarityHost()}/providers/${state.dictKey}?isPreview=true`,
            );
        }

        if (state && condition) {
            setCurrentLink(
                `${getHelloKlarityHost()}/condition/${condition.dictKey}?state=${state.dictKey}&isPreview=true`,
            );
        }

        if (!state && condition) {
            setCurrentLink(
                `${getHelloKlarityHost()}/condition/${condition.dictKey}?isPreview=true`,
            );
        }
    }, [state, condition]);

    const refresh = () => {
        sendRefresh();
    };

    useImperativeHandle(ref, () => ({
        refresh,
    }));

    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.wrapIn}>
                <iframe
                    style={{
                        height: '70vh',
                        width: '100%',
                    }}
                    onLoad={() => {
                        setLoading(false);
                    }}
                    src={currentLink}
                    scrolling="auto"
                    id="plp-preview"
                    title="preview"
                />
            </div>
        </div>
    );
});

export default ProviderPreview;
