import { type } from 'os';
import { TPackage } from './common';

export enum EConfigType {
    STRING = 'string',
    NUMBER = 'number',
    IMG = 'img',
}

export type TConfigModuleConfig = {
    type: EConfigType,
    name: string,
};

export enum EConfigCtaType {
    SELECT = 'S',
    TEXT = 'T',
}

export type TCta = {
    id: number;
    name: string;
    imageUrl?: string;
    aiPrompt?: string;
    placeholder?: string;
    isDefault?: boolean; //is init
    type: EConfigCtaType;
};

export enum EConfigMoudleType {
    LIST = 'L',
    TEXT = 'T',
}

export type TConfigModule = {
    id: number,
    iconUrl?: string;
    name: string;
    description: string;
    type: EConfigMoudleType;
    ctas?: TCta[];
    canSubmit?: boolean;
    onClick?: () => void;
    loading?: boolean;
    disabled?: boolean;
};

export enum EAIEditorType {
    ABOUTME = 'ABOUTME',
    PROSPECT = 'PROSPECT',
    TREATMENT_PHILOSOPHY = 'TREATMENT_PHILOSOPHY',
    PRACTICE_DESCRIPTION = 'PRACTICE_DESCRIPTION',
    HEADLINES = 'HEADLINES',
}

export type TFetchAiCtaResultRes = {
    ctaId: number;
    conversionId?: string;
    type: EAIEditorType;
    content?: string;
    prompt?: string;
    providerId?: number;
};

export type TFeedbackAiResultRes = {
    type: EAIEditorType;
    messageId?: string;
    like: boolean;
};

export type TStreamingChatRes = {
    inputs: {},
    query: string,
    user: string,
    response_mode: 'streaming'
    conversation_id?: string,
    do_evaluation: false
};

export type TStreamingChatRep = {
    event: string, // 'agent_message',
    conversation_id: string, //'570a0570-af66-42b5-ae2d-c228e46dd7e2',
    message_id: string, //'d4533480-d187-4b10-92a3-b773ec545e6a',
    created_at: number, //1715762134,
    task_id: string, // '589227e0-c2a9-4b02-a1c1-271a07a0c228',
    id: string, //'d4533480-d187-4b10-92a3-b773ec545e6a',
    answer?: string, //' in'
};

export type TProviderTableRes = {
    inputs: {
        conversation_id: string,
        message_id: string,
        user_id: string
    },
    user: string,
};

export type TFeedbackMessageRes = {
    rating: 'like' | 'dislike',
    tag: string,
    comment: string,
    user: string
};

export type TPromptMessage = {
    id: string, //'f2d93d63-c5c2-49d2-8d87-d56c8a0b9171',
    conversation_id: string, //'524baf4b-4d58-4421-b278-e1ab6f2e8169',
    inputs?: {},
    query: string, //'Look for a prescribe in TX who has experiences with ADHD medications. The provider’s initial session fee should be below $130.',
    answer: string, // 'I found a provider in Texas who has experience with ADHD medications and offers an initial session fee below $130:\n\n- **Michele Heyman, LPC**\n  - **Years of Experience:** 20 years\n  - **Specialty:** Mental Health\n  - **Conditions Treated:** Anxiety, Depression, ADD/ADHD, PTSD, Insomnia, ADHD\n  - **Initial Online Therapy Session Fee:** $129\n  - **Service Length:** 60 minutes\n  - **Type:** Video\n  - **States Licensed:** Texas (TX), New Mexico (NM)\n  - ![Michele Heyman, LPC](https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/provider/image/airtable/michele-heyman.png)\n  - [Book an appointment with Michele Heyman, LPC](https://care.helloklarity.com/booking?state=TX&provider=6552b750ea5b4b59224992c1&service=9bd92ead-f510-460a-85ac-dd61997de506)\n\nPlease note that this session is conducted via video.',
    message_files?: string[],
    feedback?: string,
    retriever_resources?: string[],
    created_at?: number,
    agent_thoughts?: unknown[],
    status?: string, //'normal',
    error?: string
};

export type TChatMessage = {
    query?: string, //'Look for a prescribe in TX who has experiences with ADHD medications. The provider’s initial session fee should be below $130.',
    answer?: string, // 'I found a provider in Texas who has experience with ADHD medications and offers an initial session fee below $130:\n\n- **Michele Heyman, LPC**\n  - **Years of Experience:** 20 years\n  - **Specialty:** Mental Health\n  - **Conditions Treated:** Anxiety, Depression, ADD/ADHD, PTSD, Insomnia, ADHD\n  - **Initial Online Therapy Session Fee:** $129\n  - **Service Length:** 60 minutes\n  - **Type:** Video\n  - **States Licensed:** Texas (TX), New Mexico (NM)\n  - ![Michele Heyman, LPC](https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/provider/image/airtable/michele-heyman.png)\n  - [Book an appointment with Michele Heyman, LPC](https://care.helloklarity.com/booking?state=TX&provider=6552b750ea5b4b59224992c1&service=9bd92ead-f510-460a-85ac-dd61997de506)\n\nPlease note that this session is conducted via video.',
    providerIds?: string,
    messageId?: string,
    done?: boolean,
};

export type THistoryMessage = {
    id: string, // 'f2d93d63-c5c2-49d2-8d87-d56c8a0b9171',
    conversation_id: string, // '524baf4b-4d58-4421-b278-e1ab6f2e8169',
    inputs: {},
    query?: string, //'Look for a prescribe in TX who has experiences with ADHD medications. The provider’s initial session fee should be below $130.',
    answer?: string, // 'I found a provider in Texas who has experience with ADHD medications and offers an initial session fee below $130:\n\n- **Michele Heyman, LPC**\n  - **Years of Experience:** 20 years\n  - **Specialty:** Mental Health\n  - **Conditions Treated:** Anxiety, Depression, ADD/ADHD, PTSD, Insomnia, ADHD\n  - **Initial Online Therapy Session Fee:** $129\n  - **Service Length:** 60 minutes\n  - **Type:** Video\n  - **States Licensed:** Texas (TX), New Mexico (NM)\n  - ![Michele Heyman, LPC](https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/provider/image/airtable/michele-heyman.png)\n  - [Book an appointment with Michele Heyman, LPC](https://care.helloklarity.com/booking?state=TX&provider=6552b750ea5b4b59224992c1&service=9bd92ead-f510-460a-85ac-dd61997de506)\n\nPlease note that this session is conducted via video.',
    feedback?: string,
    retriever_resources: string[],
    created_at: number,
    agent_thoughts: string[],
    status: string, // 'normal',
    error?: null
};

export type TTableProvider = {
    providerId: string;// '6552b750ea5b4b59224992c1',
    source: 'A' | 'E',
    firstName: string;//'Michele',
    middleName: string;//'',
    credential: string;
    lastName: string;//'Heyman',
    photos: string[];
    inPerson: boolean,
    lowestPrice: number,
    highestPrice: number,
    initialList?: TPackage[],
    consultationList?: TPackage[],
};

export type TConversation = {
    created_at: number,
    id: string, //'3aa330af-2bfe-4b20-bf17-5d7b5262b8ec'
    inputs: {},
    introduction: string,
    name: string,
    status: string
};

export type TTableData = {
    providers: TTableProvider[],
    states: string[],
};

export enum EAvaTone {
    FRIENDLY = 'Friendly',
    CASUAL = 'Casual',
    EMPATHETIC = 'Empathetic',
    FORMAL = 'Formal',
}

export type TProspectAvaConfig = {
    aiPrompt: string, // "Ensure the tone is patient friendly and jargon free."
    avaAutoReply: boolean,
    avaTone: EAvaTone,
    avaToneId: number,
    id?: number,
    providerId: number,
    smsNotificationEnabled?: boolean,
};
export type TNotifyInfo = {
    notificationEmail: string,
    notificationPhoneNumber: string,
    tel: string
};
