const PREFIX = 'box_practice';

export enum EKey {
    SKIP_CHANNEL_CARE = 'skip_channel_care',
    RESET_FAIL_EMAIL = 'reset_fail_email',
    AI_SEARCH_NAME = 'ai_search_name',
    AI_SEARCH_ID = 'ai_search_id',
    SHOW_KIWI_PLAN_ADVERTISE = 'show_kiwi_plan_advertise',
    CLICK_SKIP_WEBINAR = 'click_skip_webinar',
    CONFIG_PROGRAM_FROM_TABLE = 'config_program_from_table',
    CONFIGING_PROGRAM_ID = 'configing_program_id',
    SHOW_WEBINAR_POP_IN_CURRENT_SESSION = 'show_webinar_pop_in_current_session',
    HELP_CENTER_ACTIVE_KEY = 'help_center_active_key'
}

const getKey = (key: EKey): string => {
    return `${PREFIX}_${key}`;
};

const setSkipChannelCare = (data: boolean) => {
    const key = getKey(EKey.SKIP_CHANNEL_CARE);
    if (!data) {
        sessionStorage.removeItem(key);
    }
    sessionStorage.setItem(key, JSON.stringify(data));
};

const getSkipChannelCare = (): boolean => {
    const key = getKey(EKey.SKIP_CHANNEL_CARE);
    const str = sessionStorage.getItem(key);
    if (!str) {
        return false;
    }
    return true;
};

const setResetFailEmail = (email?: string) => {
    const key = getKey(EKey.RESET_FAIL_EMAIL);
    if (!email) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, email);
};

const getResetFailEmail = (): string | undefined => {
    const key = getKey(EKey.RESET_FAIL_EMAIL);
    const str = sessionStorage.getItem(key);
    return str || undefined;
};

const setAiSearchName = (name: string) => {
    const key = getKey(EKey.AI_SEARCH_NAME);
    if (!name) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, name);
};

const getAiSearchName = (): string | undefined => {
    const key = getKey(EKey.AI_SEARCH_NAME);
    const str = sessionStorage.getItem(key);
    return str || undefined;
};

const setAiSearchId = (id: string) => {
    const key = getKey(EKey.AI_SEARCH_ID);
    if (!id) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, id);
};

const getAiSearchId = (): string | undefined => {
    const key = getKey(EKey.AI_SEARCH_ID);
    const str = sessionStorage.getItem(key);
    return str || undefined;
};

const setShowKiwiPlanAdvertise = (val: boolean | undefined) => {
    const key = getKey(EKey.SHOW_KIWI_PLAN_ADVERTISE);
    if (!val) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, `${val}`);
};

const getShowKiwiPlanAdvertise = (): boolean | 'idel' => {
    const key = getKey(EKey.SHOW_KIWI_PLAN_ADVERTISE);
    const str = sessionStorage.getItem(key);
    if (!str) {
        return 'idel';
    }
    return str === 'true';
};

const getClickSkipWebinar = (): boolean => {
    const key = getKey(EKey.CLICK_SKIP_WEBINAR);
    const str = sessionStorage.getItem(key);
    return !!str;
};

const setClickSkipWebinar = (val: boolean | undefined) => {
    const key = getKey(EKey.CLICK_SKIP_WEBINAR);
    if (!val) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, `${val}`);
};

const setConfigProgramFromTable = (val: boolean | undefined) => {
    const key = getKey(EKey.CONFIG_PROGRAM_FROM_TABLE);
    if (!val) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, `${val}`);
};

const getConfigProgramFromTable = (): boolean => {
    const key = getKey(EKey.CONFIG_PROGRAM_FROM_TABLE);
    const str = sessionStorage.getItem(key);
    return !!str;
};

const setConfigingProgramId = (id?: string) => {
    const key = getKey(EKey.CONFIGING_PROGRAM_ID);
    if (!id) {
        sessionStorage.removeItem(key);
        return;
    }
    sessionStorage.setItem(key, id);
};

const getConfigingProgramId = (): string | undefined => {
    const key = getKey(EKey.CONFIGING_PROGRAM_ID);
    const str = sessionStorage.getItem(key);
    return str || undefined;
};

const setHasShowWebinarPopInCurrentSession = (data: boolean) => {
    const key = getKey(EKey.SHOW_WEBINAR_POP_IN_CURRENT_SESSION);
    if (!data) {
        sessionStorage.removeItem(key);
    } else {
        sessionStorage.setItem(key, 'true');
    }
};

const getHasShowWebinarPopInCurrentSession = (): boolean => {
    const key = getKey(EKey.SHOW_WEBINAR_POP_IN_CURRENT_SESSION);
    const str = sessionStorage.getItem(key);
    if (!str) {
        return false;
    }
    return true;
};

const setHelpCenterActiveKey = (key?: string) => {
    const k = getKey(EKey.HELP_CENTER_ACTIVE_KEY);
    if (!key) {
        sessionStorage.removeItem(k);
        return;
    }
    sessionStorage.setItem(k, key);
};

const getHelpCenterActiveKey = (): string | undefined => {
    const k = getKey(EKey.HELP_CENTER_ACTIVE_KEY);
    const str = sessionStorage.getItem(k);
    return str || undefined;
}

export {
    setSkipChannelCare,
    getSkipChannelCare,
    setResetFailEmail,
    getResetFailEmail,
    setAiSearchName,
    getAiSearchName,
    setAiSearchId,
    getAiSearchId,
    setShowKiwiPlanAdvertise,
    getShowKiwiPlanAdvertise,
    getClickSkipWebinar,
    setClickSkipWebinar,
    setConfigProgramFromTable,
    getConfigProgramFromTable,
    setConfigingProgramId,
    getConfigingProgramId,
    setHasShowWebinarPopInCurrentSession,
    getHasShowWebinarPopInCurrentSession,
    setHelpCenterActiveKey,
    getHelpCenterActiveKey,
};
