import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { EDISPUTE_STATUS, EStatus, ServerMeta } from './common';

export enum EProgressForProvider {
    PENDING_REVIEW = 'Pending Review',
    FOLLOW_UP_WITH_PROVIDER = 'Follow-up with Provider',
    APPLICATION_APPROVED = 'Application approved',
    APPLICATION_ON_HOLD = 'Application On-Hold',
    BP_ACCOUNT_ENTERED = 'BP Account Entered',
}

export interface GetOpsListInput {
    nameCond?: string;
    auditStatusList?: Array<CheckboxValueType>;
    applicationStatusList?: Array<CheckboxValueType>;
    orderKey?: string;
    orderType?: string;
    current: number;
    size: number;
}

export interface GetListCommon {
    size: number,
    current: number
}

export interface GetOpsUniprofileInput {
    nameCond?: string;
    providerId?: string;
    auditStatusList?: Array<string>;
    uniprofileStatusList?: Array<string>;
    orderKey?: string;
    orderType?: string;
    assignee?: string;
    current: number;
    size: number;
    providerPayPlanTypeList?: string[],
    payStatusList?: string[],
    archiveStatusList?: string[],
    sourceList?: string[],
    couponNameList?: string[],
    promotionCodeList?: string[],
    contractVersion?: string[]; // 2024-07-30 后端接收的参数改为了string[]
    updateFlagList?: boolean[];
    productList?: string[];
    uidPublic?: string;
    latestLaunchAt?: string;
    firstLaunchAt?: string;
}

export interface GetOpsDetailInput {
    channelId: number;
    providerId?: number;
    step: string;
    account?: string;
    password?: string;
    googleTitle?: string;
}

export interface GetOpsUniprfoleDetailInput {
    channelId?: number;
    providerId: number | string;
    step: string;
}

export interface SelectProviderChannelPhotoInput {
    providerId: number,
    selectedPhoto: string,
}

export interface ProviderChannelPhotoInput {
    providerId: number,
    selectedPhoto: string,
}

export interface GetProfileReviewListInput {
    channelId: number,
    current: number,
    providerId: number,
    size: number,
}

export interface UpdateProviderChannelAuditStatusInput {
    channelId: number;
    providerId: number;
    rejectReason?: string;
    passed: boolean;
    website?: string;
    googleTitle?: string;
    channelMappingAccount?: string;
    channelMappingPassword?: string;
}

export interface UpdateUniprofileAuditStatusInput {
    providerId: number;
    rejectReason?: string;
    passed: boolean;
}

export interface UpdateOpsChannelWebsiteInput {
    channelId: number;
    providerId: number;
    website: string;
    channelMappingAccount?: string;
    channelMappingPassword?: string;
    googleTitle?: string;
}

export interface ProviderChannelAuditGoLiveInput {
    channelId: number;
    providerId: number;
}

export interface ProviderReview {
    providerId: number;
    channelId: number;
    reviewerName: string;
    reviews: string;
    ratings: number;
    verified: boolean;
    anonymous: boolean;
}

export interface ListToChannelTableRecord {
    applicationStatus: string;
    applicationStatsName: string;
    id: number;
    providerId: number;
    firstName: string;
    lastName: string;
    email: string;
    photo: string;
    channelId: number;
    channelName: string;
    submitTime: string;
    status: string;
    statusName: string;
    assignee?: string;
    profileCompleteStatus?: string;
    archiveStatus?: 'Active' | 'Archived';
    providerPayPlanType: string;
    channelPayPlanType: string;
    product: EProduct;
}

export interface WithDrawChannelTableRecord {
    id: number;
    providerId: number;
    firstName: string;
    lastName: string;
    email: string;
    photo: string;
    channelId: number;
    channelName: string;
    submitTime: string;
    assignee?: string;
    providerPayPlanType: string;
    channelPayPlanType: string;
    listTime: null;
    assigneeUid: null;
    withdrawReason: string;
    status: string;
    statusName: string;
    delListTime?: string;
    archiveStatus?: EStatus;
}

export interface UpdateAssigneeInput {
    assignee: string;
    assigneeUid: number;
    channelId?: number;
    providerId: number;
}

export interface UpdateNotesInput {
    notes: string;
    channelId?: number;
    providerId: number;
}

export enum EChanelAuditStatus {
    UNIPROFILE_NEEDED = 'uniprofile-needed',
    UPLOAD_DATA = 'upload-data',
    WAITING_APPROVE = 'waiting-approve',
    FAILED = 'failed',
    APPROVED = 'approved',
}

export enum EChanelAuditStatusName {
    UNIPROFILE_NEEDED = 'Uniprofile Needed',
    UPLOAD_DATA = 'Upload data to Channel',
    WAITING_APPROVE = 'Waiting for Channels Approve',
    FAILED = 'Failed',
    APPROVED = 'Approved',
}

export enum EAuditStatus {
    FOLLOW_UP = 'follow-up',
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export enum EAuditStatusName {
    FOLLOW_UP = 'Need follow-up',
    PENDING = 'Pending approval',
    APPROVED = 'Approved',
    REJECTED = 'Disqualified',
}

export enum EUniprofileStatus {
    REQUIRED = 'required',
    UPDATE_IN_PROGRESS = 'update-in-progress',
    UNDER_REVIEW = 'under-review',
    UPDATE_SUBMITTED = 'update-submitted',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export enum EUniprofileStatusName {
    REQUIRED = 'UniProfile not created',
    UPDATE_IN_PROGRESS = 'UniProfile in-progress',
    UNDER_REVIEW = 'UniProfile under review',
    UPDATE_SUBMITTED = 'Updates submitted',
    APPROVED = 'UniProfile approved',
    REJECTED = 'UniProfile disqualified',
}

export enum ESource {
    SCL = 'SCL',
    W = 'W',
    HEADWAY = 'headway',
}

export enum ESourceName {
    SCL = 'Contract portal',
    W = 'Website',
    headway = 'Headway landing page',
    REFERRAL = 'Referral',
}
export interface ProviderWithUniprofileStatus {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    createTime: string;
    submitTime: string;
    auditStatus: EAuditStatus;
    auditStatusName: EAuditStatusName;
    uniprofileStatus: EUniprofileStatus;
    uniprofileStatusName: EUniprofileStatusName;
    assignee: string;
    assigneeUid: number;
    verify: boolean;
    archiveStatus?: EStatus;
    source?: ESource
    contract: string[]
    updateFlag?: boolean,
}

export interface GetOpsChannelWithdrawListInput {
    nameCond?: string;
    auditStatusList?: Array<CheckboxValueType>;
    applicationStatusList?: Array<CheckboxValueType>;
    orderKey?: string;
    orderType?: string;
    providerPayPlanTypeList?: Array<CheckboxValueType>;
    withdrawReasonList?: Array<CheckboxValueType>;
    channelPayPlanType?: Array<CheckboxValueType>;
    channelPayPlanTypeList?: Array<CheckboxValueType>;
    current: number;
    size: number;
}

export interface GetOpsChannelWithdrawDetailInput {
    id: number;
    channelId: number;
    providerId?: number;
}

export interface UpdateOpsChannelWithdrawStatusInput {
    id: number;
    channelId: number;
    providerId: number;
    passed: boolean;
}

export interface UpdateOpsChannelWithdrawNotesInput {
    notes: string;
    channelId: number;
    providerId: number;
    id: number;
}

export interface UpdateOpsChannelWithdrawAssigneeInput {
    assignee: string;
    assigneeUid: number;
    channelId?: number;
    providerId: number;
    id: number;
}

export type RoleItem = {
    id: number,
    name: string,
    providers: boolean,
    account: boolean,
    uniprofile: boolean,
    channels: boolean,
    channel_store: boolean,
    channel_listing: boolean,
    service_template: boolean,
    invite_member: boolean,
    add: boolean,
    super_edit: boolean,
    admin_edit: boolean,
    operator_edit: boolean,
    web_app_dev: boolean,
};

export interface IGetBookingLinkListResp {

}

export interface ISaveHelloKlarityBookingLinkInput {
    bpProviderId: number;
    directBookingLink: string;
    id: number | null;
    specialty: string;
    state: string;
    serviceType: string;
}

export enum EOriderType {
    DESC = 'desc',
    ASC = 'asc',
}

export interface IGetProviderMappingDataInput {
    airtableProviderId?: string;
    bookLink?: number[];
    current?: number;
    ehrProviderId?: number;
    intakeqProviderId?: string;
    listingStatus?: string[];
    mappingStatus?: string[];
    name?: string;
    orderKey?: string;
    orderType?: EOriderType;
    patientRating?: number[];
    providerNameSlug?: string;
    size?: number;
    title?: string;
    type?: number;
    micStatus?: string[];
    ehrSetUpStatus?: boolean[];
    uidPublic?: string;
    programIdList?: number[];
    autoReschedule?: boolean;
}

export enum EProviderListMappingStatusValue {
    PENDING_REVIEW = 'PR',
    LISTED = 'L',
    UNLISTED = 'U',
    DISQUALIFIED = 'D',
    UNIPROFILE_NEEDED = 'UN',
    PAUSE_LISTING = 'PL',
}

export enum EProviderMappingStatusValue {
    UNMAPPED = 'U',
    EHR_MAPPED = 'E',
    AIR_TABLE_MAPPING = 'A',
}

export interface IBPMappingItem {
    id: number,
    bpProviderId: number,
    ehrProviderId?: number,
    intakeqProviderId?: string,
    airtableProviderTitle?: string;
    airtableProviderId?: string,
    providerNameSlug: string,
    firstName: string,
    middleName?: string,
    lastName: string,
    email: string,
    title?: string,
    listingStatus: EProviderListMappingStatusValue,
    mappingStatus: EProviderMappingStatusValue,
    profileLink?: string,
    bookLink: number,
    patientRating: number,
    lastUpdateAt?: string,
    conflict?: boolean;
    micStatus?: 'Effective' | 'None' | 'Expired',
    uniprofileStatus?: 'approved' | string,
    ehrSetUpCompleted?: boolean;
    w9Form?: string; //"provider/w_9/2024-06/5749b48b-edae-494f-b9f2-7d21f966ba9e.jpg",
    latestLaunchAt?: string;
    firstLaunchAt?: string;
    hasBookingLink?: boolean;
    turnOnSpecialtyList?: string[];
    turnOnAllSpecialty?: boolean;
    turnOffSpecialtyList?: string[];
    turnOffAllSpecialty?: boolean;
    autoReschedule?: boolean;
}

export interface IProviderMappingDataResp {
    records?: IBPMappingItem[];
    total: number;
    size: number,
    current: number,
    orders: string[],
    optimizeCountSql: boolean,
    searchCount: boolean,
    countId?: number,
    maxLimit?: number,
    pages: number
}

export interface IUpdateProviderSlugInput {
    id: number;
    providerNameSlug: string;
}

export interface IUpdatePatientRatingInput {
    id: number;
    patientRating: number;
}

export interface IUpdateProviderTitleInput {
    id: number;
    providerNameSlug: string;
}

export interface IUpdateHelloKlarityListingStatusInput {
    id: number;
    listingStatus: EProviderListMappingStatusValue
}

export interface IUpdateHelloKlarityAirtableTitleInput {
    id: number;
    airtableProviderTitle: string
}

export interface IUpdateHelloKlarityMappingStatusInput {
    id: number;
    mappingStatus: EProviderMappingStatusValue
}

export interface IHelloKlarityBookingLink {
    bpProviderId: number;
    createAt?: string;
    createBy?: number;
    directBookingLink: string;
    id?: number;
    isVisible?: number;
    lastUpdateAt?: string;
    lastUpdateBy?: number;
    offerId: number;
    serviceType: string;
    specialty: string;
    state: string;
    bookingLinkName?: string;
    uuid?: string;
    conditions?: string | string[];
}
export interface ISaveSpecialtiesHighlightInput {
    providerId: number;
    specialtyHighlight: string;
}

export interface ISaveAirtableProviderInput {
    airtableProviderId: string;
    airtableProviderTitle: string;
    intakeqProviderId: string;
    listingStatus: EProviderListMappingStatusValue;
    providerNameSlug: string;
}

export enum EAppointmentType {
    VIDEO = 'Video Call',
    IN_PERSON = 'In Person',
}

export type TAppointment = {
    providerId?: string,
    ehrProviderId?: string,
    airtableProviderId?: string,
    intakeqProviderId?: string,
    serviceId: string,
    url?: string, //ehr return only
    offeringId: string,
    name: string,
    length: number, //min
    price: string,
    type: EAppointmentType[]
};

export interface IHelloKlarityViewPostMessageData {
    tabKey: string,
    hightlights: string[],
    appointmentList: TAppointment[],
    hasStorage: boolean,
    genericDraftData?: ISaveGenericInfoReqInput,
    specificDraftData?: ISaveSpecificInfoReqListInput
}

export interface IGenerateSaleLinkInput {
    fullName: string,
    inviteEmail: string,
    phoneNumber: string,
    payPlanId: number,
    couponId?: string,
    promotionCode: string,
    message: string,
    onboardingCallBookLink: string,
    presentationEmbeddedLink: string,
}

export type TOpsUserInfo = {
    id: number,
    firstName: string,
    lastName: string,
    gmail: string,
    tel?: string,
    bookingLinkUrl?: string,
    title?: string,
    password: null,
    accessToken: null,
    role: string,
    permission: null,
    updateTime?: string
};

export type TContentInfo = {
    id?: number,
    pageId?: number,
    title: string,
    content: string,
    imgUrl?: string,
    createAt: string, //'2024-05-20T12:34:01.000+00:00'
    createBy: number,
    updateAt: string, //'2024-05-20T12:34:01.000+00:00'
    updateBy: number,
    status: 'A' | 'D'
};

export enum EInternalLinkType {
    'SPECIALTY_DOCTOR' = 'specialty-doctor',
    'HEALTHY_CARE_SERVICE' = 'healthy-care-service',
    'FIND_DOCTOR' = 'find-doctor',
}

export type TInternalLink = {
    id?: number,
    type?: EInternalLinkType,
    linkTag: string,
    linkName?: string,
    linkUrl?: string,
    targetItem?: ServerMeta,
    pageId: number,
    seq: number,
    createAt?: string, //'2024-05-20T12:34:01.000+00:00'
    createBy?: number,
    updateAt?: string, //'2024-05-20T12:34:01.000+00:00'
    updateBy?: number,
};

export type TFaqInfo = {
    id?: number,
    pageId: number,
    question: string,
    answer: string,
    seq: number,
    createAt?: string, //'2024-05-22T06:58:04.000+00:00',
    createBy?: number,
    updateAt?: string, //'2024-05-22T06:58:17.000+00:00',
    updateBy?: 43
};

export interface IGetHelloKlarityPageManagementResp {
    name?: string[]; //condition,
    state?: string[];// ["AC","CA"],
    type: string;//'STATE'|'CONDITION'|'HOME',
    statusList?: ('ACTIVE' | 'INACTIVE')[], //'ACTIVE'|'INACTIVE',
    orderKey?: string;//'LAST_UPDATE_AT',
    orderType?: string; // 'DESC''ASC',
    size: number,
    current: number
}

export interface IGeAddOnResp {
    providerName?: string;
    productCodeList?: string[];
    paymentStatusList?: ('Paid' | 'Failed')[], //'ACTIVE'|'INACTIVE',
    size: number,
    current: number
    orderKey?: string;
    orderType?: string;
}

export enum EProduct {
    KIWI = 'Kiwi',
    KLARITY = 'Klarity',
}

export interface IPerformanceInput {
    name?: string;
    current: number;
    size: number;
    orderField?: string;
    orderType?: string
}

export type TPerformanceItem = {
    avgRating: number;
    bpProviderId: number;
    email: string;
    firstName: string;
    intakeqProviderId: string;
    lastName: string;
    latestReviewAt: string;
    providerSource: string;
    tags?: string[];
    totalReviewCount: number;
};

export interface IReviewInput {
    'bpProviderId'?: number;
    'intakeqProviderId'?: number;
    'providerSource'?: string;
    'name'?: string;
    'publish'?: boolean | string;
    'needReply'?: boolean | string;
    'ratingFilterType'?: string; //ONLY_5 ONLY_4  ONLY_3  ONLY_2 ONLY_1  GTE_3  LESS_THEN_3  NONE
    'reviewDetail'?: string;
    'tag'?: string;
    'beginDate'?: Date;
    'endDate'?: Date;
    'patientEmail'?: string;
    'current'?: number;
    'size': number;
    'orderField'?: string; //replied_at submit_at
    'orderType'?: string; //asc desc
    disputeDecisionBy?: string;
    disputeStatusList?: EDISPUTE_STATUS[];
    disputeReasonList?: string[];
    disputeBeginDate?: Date;
    disputeEndDate?: Date;
}

export interface IReviewItem {
    id: number;
    'bpProviderId'?: number;
    'intakeqProviderId'?: number;
    'providerSource'?: string;
    'name'?: string;
    'publish'?: boolean | string;
    'needReply'?: boolean | string;
    'ratingFilterType'?: string; //ONLY_5 ONLY_4  ONLY_3  ONLY_2 ONLY_1  GTE_3  LESS_THEN_3  NONE
    'reviewDetail'?: string;
    'tag'?: string;
    'beginDate'?: string;
    'endDate'?: string;
    'patientEmail'?: string;
    'email'?: string;
}

export interface IReviewSummaryInput {
    'providerId'?: number | string
    'providerSource': string;
    'summaryRange': string;
}

export interface IDisputeReviewInput {
    disputeStatusList?: EDISPUTE_STATUS[]
    disputeReasonList?: string[];
    'disputeDecisionBy'?: string;
    'name'?: string;
    'ratingFilterType'?: string; //ONLY_5 ONLY_4  ONLY_3  ONLY_2 ONLY_1  GTE_3  LESS_THEN_3  NONE
    'reviewDetail'?: string;
    'beginDate'?: Date;
    'endDate'?: Date;
    'current'?: number;
    'size': number;
    'orderField'?: string; //replied_at submit_at
    'orderType'?: string; //asc desc
}

export interface IDisputeDissionSubmitInput {
    reviewId: number;
    reason: string;
    disputeStatus: EDISPUTE_STATUS;
}

export interface IUpdateReviewtToPrivateInput {
    reviewId: number;
    reason: string;
}
export interface TAssessment {
    id: number;
    name: string;
}

export interface IAssessmentInput {
    id?: string;
    assessmentName?: string;
    tags?: string[] | string;
    orderKey?: string;
    orderType?: string;
    size?: number;
    current?: number;
    status?: string
}

export type TAssessmentItem = {
    id?: number | string;
    uuid?: string | number;
    questionsFormLink?: string;
    questionsFormLinkId?: number | string;
    resultLink?: string;
    questionsFormResultLink?: string;
    updateAt?: string;
    assessmentName: string;
    conditionKeyTags?: string;
    tags?: string[];
    description?: string;
    finishMinute?: number;
    imgUrl?: string;
    participantsNumber?: number;
    title?: string;
    fromType?: string;
    questionNumber?: number;
    emailPrompt?: string;
    emailTemplateId?: number;
    promptManagement: string;
    analyzingPrompt?: string;
    status: string;
};

export type TEngagementQuestion = {
    id?: number;
    title: string,
    type?: string,
    imgUrl?: string;
    seq?: number;
    assessmentId?: number | string;
};

export type TResult = {
    title: string,
    description: string;
    id?: string;
    riskLevel?: number;
    header?: string;
    imgUrl?: string;
    primaryCta?: string;
    primaryCtaUrl?: string;
    secondaryCta?: string;
    secondaryCtaUrl?: string;
    assessmentId?: number | string;

};

export type TPreviewResult = Partial<TResult> & {
    type: string;
};

export type TPreviewQuestion = {
    count?: number;
    questionsFormLink?: string;
    engagementQuestionCount?: number;
    questionsPreviewLink?: string;
}
& Partial<
TEngagementQuestion & {
    type: string;
}
>;

export type TQuestionItem = {
    createAt?: string;
    createBy?: number;
    googleFormId?: number;
    id?: number;
    imgUrl?: string;
    options?: any[];
    questionId?: string;
    questionIdHex?: string;
    required?: boolean;
    title: string;
    titleHashCode?: string;
    type?: string;
    updateAt?: string;
    updateBy?: number;
    isFixed?: boolean;
    seq?: number;
};

export interface IAssessmentEmail {
    emailPrompt: string;
    riskLevel: number;
    subject: string;
    bannerImageUrl: string;
    ctaText: string;
    ctaUrl: string;
    assessmentId: number;
    emailTemplateId: number;
}

export interface IEmailListItem {
    content: string;
    id: number;
    title: string;
    type: string;
}

export interface ISaveGenericInfoReqInput {
    tags?: string[],
    prescribableMedications?: string[],
    nonPrescribableMedications?: string[],
}
export interface ISaveSpecificInfoReqListInput {
    condition?: string;
    headLines?: string;
    aboutMe?: string;
    tags?: string[];
    prescribableMedications?: string[];
    nonPrescribableMedications?: string[];
    specialty?: string[];
    specialtyHighlight?: string[];
    conditionTreated?: string[];
}

export interface IProfileDataSaveInput {
    genericInfoReq: ISaveGenericInfoReqInput;
    specificInfoReqList: ISaveSpecificInfoReqListInput[];
}
