import React, { useState } from 'react';
import { Modal, Form, Input, Button, message, Select } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { getKlarityResourcesHistory, setOrUpdateKlarityResourcesDetail, deleteKlarityResources } from 'api/operation';

import { TResource } from 'types/common';
import { REQUIRED_FIELD, RESOURCE_CATEGORY } from 'constants/common';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { set } from 'lodash';
import useKlarityResource from 'hooks/useKlarityResource';
import useKlarityResourceHistory from 'hooks/useKlarityResourceHistory';
import { getTimeStr } from 'utils/calendar';
import dayjs from 'dayjs';

type Props = {
    isOps?: boolean
    item?: TResource
    onCancel?: () => void
    onSuccess?: () => void
};

const CreateAndEditResourceModal = ({ item, onCancel, onSuccess }: Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<TResource>();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [resourceUrl, setResourceUrl] = useState('');
    const [tabKey, setTabKey] = useState('1');
    const [itemList, loading, refetch] = useKlarityResourceHistory(item?.id);
    const [isDeleting, setIsDeleting] = useState(false);
    const { confirm } = Modal;
    const isEdit = !!item?.id;

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;

            let url = formValues.resourceUrl;
            if (url && !url.startsWith('http')) {
                url = 'https://' + url;
            }

            const params: TResource = {
                title: formValues.title,
                description: formValues.description,
                resourceUrl: url,
                category: formValues.category,
                status: "Published",
            };
            if (isEdit) {
                params.id = id;
                params.status = item?.status || "Unpublished";
            }
            const apiFunc = setOrUpdateKlarityResourcesDetail;
            const { data } = await apiFunc(params);
            if (data.code === 200) {
                if (!isEdit) {
                    message.success('Resource added successfully');
                } else {
                    message.success('Resource updated successfully');
                }
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    const deleteResource = async () => {
        if (!item?.id) {
            return;
        }
        setIsDeleting(true);
        const result = await deleteKlarityResources(item.id);
        setIsDeleting(false);
        if (!result.error) {
            message.info('Resource deleted successfully');
            onSuccess?.();
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                title: item.title,
                description: item.description,
                resourceUrl: item.resourceUrl,
                category: item.category
            });
            setCategory(item.category);
            setTitle(item.title);
            setDescription(item.description);
            setResourceUrl(item.resourceUrl);
        } else {
            form.resetFields();
        }
    }, [item]);

    const canSubmit = !!(title && description && category && resourceUrl);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <div className={s.tabLabel}>Details</div>,
            children: <div className={s.tabWrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Resource title"
                            name="title"
                            rules={[{ required: true, message: REQUIRED_FIELD }]}
                        >
                            <Input
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                showCount placeholder='Enter the title of the resource' maxLength={100} style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: REQUIRED_FIELD }]}
                        >
                            <Input.TextArea
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                                style={{ width: '100%' }} maxLength={185} rows={4} showCount />
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Category"
                            name="category"
                            rules={[{ required: true, message: REQUIRED_FIELD }]}
                        >
                            <Select onChange={(val: string) => {
                                setCategory(val);
                            }}>
                                {
                                    RESOURCE_CATEGORY.map((item, index) => {
                                        return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Resource URL"
                            name="resourceUrl"
                            rules={[{ required: true, message: REQUIRED_FIELD }]}
                        >
                            <Input
                                onChange={(e) => {
                                    setResourceUrl(e.target.value);
                                }}
                                placeholder='Paste the Google Doc link where providers can access this resource.' showCount maxLength={200} style={{ width: '100%' }} />
                        </Form.Item>
                    </>
                </Form>
            </div>,
        },
        {
            key: '2',
            label: <div className={s.tabLabel}>History</div>,
            children: (
                <div className={s.tabWrap}>
                    <div className={s.historyWrap}>
                        {
                            itemList?.map((item, index) => {
                                return (
                                    <div className={s.item} key={item.id}>
                                        <div className={s.dot}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
                                            </svg>
                                        </div>
                                        <div className={s.content}>
                                            {
                                                item.createAt ?
                                                    <div className={s.date}>
                                                        {item.operationType} on <b>{getTimeStr(dayjs(item.createAt), 'MMM DD, YYYY')}</b>
                                                    </div>
                                                    :
                                                    <div className={s.date}>
                                                        {item.operationType} on <b>{getTimeStr(dayjs(item.createAt), 'MMM DD, YYYY')}</b>
                                                    </div>
                                            }
                                            <div className={s.user}>
                                                By {item.operationEmail}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Modal
            title={isEdit ? 'View resource' : 'Add an new resource'}
            closable
            className={`${commonS.modalFixHeightWrap} ${s.modalWrap}`}
            width="600px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                isEdit ?
                    <div className={s.editBtnWrap}>
                        <div className={s.left}>
                            <Button onClick={onCancel}>Cancel</Button>
                        </div>
                        <div className={s.right}>
                            <Button
                                onClick={() => {
                                    confirm({
                                        title: null,
                                        icon: <div className={s.delIcon}>
                                            <div className={s.svgWrap}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M11.9995 9V11M11.9995 15H12.0095M5.07134 19H18.9277C20.4673 19 21.4296 17.3333 20.6598 16L13.7316 4C12.9618 2.66667 11.0373 2.66667 10.2675 4L3.33929 16C2.56949 17.3333 3.53174 19 5.07134 19Z" stroke="#DC2626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            Delete this resource?
                                        </div>,
                                        content: <div className={s.delContent}>Are you sure you want to delete this resource? Once deleted, providers won’t be able to see it from the web app anymore.</div>,
                                        okText: 'Delete',
                                        okType: 'danger',
                                        cancelText: 'Cancel',
                                        wrapClassName: s.delModal,
                                        width: 600,
                                        onOk() {
                                            deleteResource();
                                        },
                                        onCancel() {
                                        },
                                    });
                                }}
                                loading={isDeleting}
                                danger type='text' className={s.del}>Delete resource</Button>
                            <Button loading={submiting} disabled={!canSubmit} type="primary" onClick={onSave}>Save changes</Button>
                        </div>
                    </div>
                    :
                    <div>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button loading={submiting} disabled={!canSubmit} type="primary" onClick={onSave}>Publish</Button>
                    </div>

            }
        >

            {
                isEdit ?
                    <div className={s.editWrap}>
                        <Tabs defaultActiveKey="1" items={items} onChange={(key) => {
                            setTabKey(key);
                        }} />
                    </div>
                    :
                    <div className={s.wrap}>
                        <Form
                            form={form}
                            className={commonS.formStyle1}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Resource title"
                                    name="title"
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                        }}
                                        showCount placeholder='Enter the title of the resource' maxLength={100} style={{ width: '100%' }} />
                                </Form.Item>

                                <Form.Item
                                    className={s.rowItem}
                                    label="Description"
                                    name="description"
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <Input.TextArea
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        style={{ width: '100%' }} maxLength={185} rows={4} showCount />
                                </Form.Item>

                                <Form.Item
                                    className={s.rowItem}
                                    label="Category"
                                    name="category"
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <Select onChange={(val: string) => {
                                        setCategory(val);
                                    }}>
                                        {
                                            RESOURCE_CATEGORY.map((item, index) => {
                                                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    className={s.rowItem}
                                    label="Resource URL"
                                    name="resourceUrl"
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            setResourceUrl(e.target.value);
                                        }}
                                        placeholder='Paste the Google Doc link where providers can access this resource.' showCount maxLength={200} style={{ width: '100%' }} />
                                </Form.Item>
                            </>
                        </Form>
                    </div>
            }
        </Modal >
    );
};

export default CreateAndEditResourceModal;
