import { Button, message, Modal, Spin, Input, AutoComplete, Empty, Typography } from 'antd';
import React, { } from 'react';
import { ServerMeta } from 'types/common';
import s from './s.module.less';
import EditFaqModal from './components/EditFaqModal';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import {
    getKlarityProviderList,
    saveKlarityProviderList,
    resetKlarityProviderList,
} from 'api/operation/pageManagement';
import type { TProvider, TProviderSortItem } from 'types/providerSort';

type Props = {
    pageId?: string;
    state?: ServerMeta;
    condition?: ServerMeta;
    pageName?: string;
    onPreview: () => void;
};

const FaqManagement = (props: Props) => {
    const { state, condition, onPreview, pageId, pageName } = props;
    const [data, setData] = React.useState<TProvider[]>([]);
    const [showEdit, setShowEdit] = React.useState(false);
    const [currentEdit, setCurrentEdit] = React.useState<TProvider>();
    const [currentEditInx, setCurrentEditInx] = React.useState<number>();
    const [loading, setLoading] = React.useState(false);
    const [targetIndex, setTargetIndex] = React.useState<number>(0);
    const [isEdit, setIsEdit] = React.useState(false);
    const inputValues = React.useRef<TProviderSortItem[]>([]);
    const [options, setOptions] = React.useState<{ value: string }[]>([]);
    const [searchText, setSearchText] = React.useState('');

    const filterData = React.useMemo(() => {
        return data.filter((item) => {
            const fullName = `${item.firstName} ${
                item.middleName ? `${item.middleName} ` : ''
            }${item.lastName}`
                .toLowerCase()
                .replace(/ /g, '');

            const normalizedSearchText = searchText
                .toLowerCase()
                .replace(/ /g, '');

            return fullName.includes(normalizedSearchText);
        });
    }, [data, searchText]);

    const onSearch = (text: string) => {
        setSearchText(text);
        const filteredOptions = data
            .filter((provider) =>
                `${provider.firstName} ${
                    provider.middleName ? `${provider.middleName} ` : ''
                }${provider.lastName}`
                    .toLowerCase()
                    .includes(text.toLowerCase()),
            )
            .map((provider) => ({
                value: `${provider.firstName} ${
                    provider.middleName ? `${provider.middleName} ` : ''
                }${provider.lastName}`,
            }));

        setOptions(filteredOptions);
    };

    React.useEffect(() => {
        onPreview?.();
    }, [data]);

    const fetchData = async (id:string) => {
        setLoading(true);
        const result = await getKlarityProviderList(id);
        if (!result.error) {
            setData((result.data?.data || []).map((item, index) => ({
                ...item,
                index,
            })));
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (pageId) {
            fetchData(pageId);
        }
    }, [pageId]);

    const handleReset = async () => {
        setLoading(true);
        try {
            const result = await resetKlarityProviderList(pageId!);
            if (!result.error) {
                message.success('Reset success');
                await fetchData(pageId!);
            }
        } catch (error) {
            message.error('Reset failed');
        } finally {
            setLoading(false);
        }
    };
    const handleSetOverallPick = async (provider: TProvider) => {
        setLoading(true);
        try {
            const result = await saveKlarityProviderList(pageId!, [{
                providerSource: provider.source,
                providerId: provider.providerId,
                sort: 1,
                isPinned: true,
            }]);
            if (!result.error) {
                message.success('Set overall pick success');
                fetchData(pageId!);
            }
        } catch (error) {
            message.error('Set overall pick failed');
        } finally {
            setLoading(false);
        }
    };

    const checkDuplicateSorts = (
        inputArray: TProviderSortItem[],
    ): boolean => {
        const sortSet = new Set();
        for (const item of inputArray) {
            if (sortSet.has(item.sort)) {
                return true;
            }
            sortSet.add(item.sort);
        }
        return false;
    };

    const checkInputValues = () => {
        if (inputValues.current.length === 0) {
            message.error('Please set the provider sort');
            return false;
        }

        if (inputValues.current.some((item) => item.sort === 0)) {
            message.error('Provider sort cannot be 0');
            return false;
        }

        if (inputValues.current.some((item) => item.sort > data.length)) {
            message.error('Provider sort cannot be greater than the total number of providers');
            return false;
        }

        if (inputValues.current.some((item) => item.sort < 1)) {
            message.error('Provider sort cannot be less than 1');
            return false;
        }

        if (checkDuplicateSorts(inputValues.current)) {
            message.error('Provider sort cannot be the same');
            return false;
        }

        return true;
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            if (!checkInputValues()) {
                return;
            }

            const result = await saveKlarityProviderList(pageId!, inputValues.current);
            if (!result.error) {
                message.success('Save success');
                setIsEdit(false);
                inputValues.current = [];
                fetchData(pageId!);
            }
        } catch (error) {
            message.error('Save failed');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (value: number, i: number) => {
        if (inputValues.current.find((item) => item.providerId === data[i].providerId)) {
            inputValues.current = [
                ...inputValues.current.filter((item) => item.providerId !== data[i].providerId),
                {
                    providerSource: data[i].source,
                    providerId: data[i].providerId,
                    sort: value,
                    isPinned: false,
                },
            ];

            return;
        }
        inputValues.current = [
            ...inputValues.current,
            {
                providerSource: data[i].source,
                providerId: data[i].providerId,
                sort: value,
                isPinned: false,
            },
        ];
    };

    const ProviderItem = ({ provider, index }: { provider: TProvider, index: number }) => {
        return (
            <div className={s.providerItem}>
                <div className={s.providerInfo}>
                    <div className={s.providerNumber}>
                        #
                        {isEdit && !provider.isPinned ? (
                            <Input
                                type="number"
                                min="1"
                                max={data.length.toString()}
                                defaultValue={(provider.index ?? 0) + 1}
                                onChange={(e) =>
                                    handleInputChange(parseInt(e.target.value, 10), provider.index ?? 0)
                                }
                                className={s.providerNumberInput}
                            />
                        ) : (
                            <>{(provider.index ?? 0) + 1}</>
                        )}
                    </div>
                    {provider.photos?.[0] ? (
                        <img
                            src={provider.photos[0]}
                            alt={provider.headline}
                            className={s.providerAvatar}
                        />
                    ) : (
                        <div className={s.providerAvatar}>
                            <UserOutlined />
                        </div>
                    )}
                    <div className={s.providerDetails}>
                        <div className={s.providerName}>
                            {provider.firstName} {provider.middleName} {provider.lastName}
                            {provider.sort && (
                                <span className={s.manuallyAdjusted}>
                                    Manually adjusted
                                </span>
                            )}
                        </div>
                        <div className={s.providerCredential}>
                            {provider.credential}
                        </div>
                    </div>
                </div>
                {!isEdit && !provider.isPinned && (
                    <Button
                        className={s.providerAction}
                        type="text"
                        style={{
                            color: '#00816b',
                        }}
                        onClick={() => {
                            Modal.confirm({
                                className: s.removeModal,
                                title: 'Set as overall pick',
                                content: `Are you sure you want to set ${provider.firstName} ${provider.middleName} ${provider.lastName} as the overall pick for the ${pageName}?`,
                                okText: 'Confirm',
                                onOk: () => {
                                    handleSetOverallPick(provider);
                                },
                            });
                        }}
                    >
                        Set as overall pick
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <span>Provider order management</span>
                {isEdit ? (
                    <div className={s.btnWrap}>
                        <Button
                            onClick={() => {
                                setIsEdit(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => handleSave()} type="primary">
                            Save
                        </Button>
                    </div>
                ) : (
                    <div className={s.btnWrap}>
                        <Button
                            onClick={() => {
                                Modal.confirm({
                                    className: s.removeModal,
                                    title: 'Reset provider order?',
                                    content:
                      'Are you sure you want to reset the provider order to the default ranking? Your current changes will be discarded.',
                                    okText: 'Reset all',
                                    onOk: () => {
                                        handleReset();
                                    },
                                });
                            }}
                            type="text"
                            style={{
                                color: '#00816B',
                            }}
                        >
                            Reset all
                        </Button>
                        <Button
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                )}
            </div>
            <div className={s.searchWrap}>
                <AutoComplete
                    style={{ width: '100%' }}
                    options={options}
                    onSearch={onSearch}
                    onSelect={onSearch}
                    placeholder="Search by name"
                    className={s.searchComplete}
                >
                    <Input prefix={<SearchOutlined />} allowClear />
                </AutoComplete>
            </div>
            <div className={s.listWrap}>
                <Spin spinning={loading}>
                    {filterData.length === 0 ? (
                        <Empty description={<Typography.Text>No data</Typography.Text>} />
                    ) : (
                        filterData.map((item: TProvider, index: number) => (
                            <ProviderItem key={item.id} provider={item} index={index} />
                        ))
                    )}
                </Spin>
            </div>
            <EditFaqModal
                onCancel={() => {
                    setShowEdit(false);
                    setCurrentEdit(undefined);
                }}
                onSuccess={() => {
                    setShowEdit(false);
                    fetchData(pageId!);
                    setCurrentEdit(undefined);
                }}
                targetIndex={targetIndex}
                pageId={pageId ? parseInt(pageId, 10) : 0}
                open={showEdit}
                item={currentEdit}
                index={currentEditInx}
            />
        </div>
    );
};

export default FaqManagement;
