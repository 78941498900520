export enum ESurveyStep {
    INTRODUCTION = 0,
    SURVEY_INTRO = 1,
    SURVEY_INVITATION = 2,
    PATIENT_INTRO = 3,
    PATIENT_IMPORT = 4,
    // REVIEW_INTRO = 6,
    // CHANNEL_SELECTION = 7,
    // REVIEW_INVITATION = 8,
}

export enum SurveyStepValue {
    INTRODUCTION = 'introduction',
    SURVEY_INTRO = 'surveyIntro',
    SURVEY_INVITATION = 'surveyInvitation',
    PATIENT_INTRO = 'patientIntro',
    PATIENT_IMPORT = 'patientImport',
    // REVIEW_INTRO = 'reviewIntro',
    // CHANNEL_SELECTION = 'channelSelection',
    // REVIEW_INVITATION = 'reviewInvitation',
}

export const SurveySteps = [
    SurveyStepValue.INTRODUCTION,
    SurveyStepValue.SURVEY_INTRO,
    SurveyStepValue.SURVEY_INVITATION,
    SurveyStepValue.PATIENT_INTRO,
    SurveyStepValue.PATIENT_IMPORT,
    // SurveyStepValue.REVIEW_INTRO,
    // SurveyStepValue.CHANNEL_SELECTION,
    // SurveyStepValue.REVIEW_INVITATION,
];

export const SurveyNonForm = [
    ESurveyStep.INTRODUCTION,
    ESurveyStep.SURVEY_INTRO,
    ESurveyStep.SURVEY_INVITATION,
    ESurveyStep.PATIENT_IMPORT,
    ESurveyStep.PATIENT_INTRO,
    // ESurveyStep.REVIEW_INTRO,
    // ESurveyStep.REVIEW_INVITATION,
];

export const SurveyFormFileds = {
    SUBTEXT: 'subtext',
    TITLE: 'title',
};

export type ISurveyEmailInput = {
    subejct: string;
    content: string;
    footer?: string;
};

export type SurveyFormListValue = {
    emoji: string;
    id: number;
    name: string;
    priority: number;
    value: string;
};

export type ISurveyForm = {
    configBackend?: string;
    configFrontend?: string;
    elementListValues: SurveyFormListValue[];
    id: number;
    isAnonymous: number;
    isOverallRating: number;
    isRequired: number;
    maxLength: number;
    priority: number;
    subtext: string;
    title: string;
};

export type ISurveyFormInput = {
    elements: ISurveyForm[],
    name?: string
};

export type ISurveyHistory = {
    id: number;
    providerId: number;
    patientId: number;
    surveyFormId: number;
    surveyUniqueKey: string;
    createAt: string;
    status: string;
    sendAt: string;
    sendInWeekId: number;
    isAnswered: number;
    answerAt: string;
    lastUpdateAt: string;
};

export type TKlarityReviewReplyInput = {
    subject: string,
    content: string,
    reviewId: number,
    isPublic: boolean,
    isConfirmPhiLeakage: boolean,
};

export type TReviewSummary = {
    avgRating: number,
    bpProviderId: number,
    createAt: string, //"2024-07-25T04:24:49"
    id: number,
    intakeqProviderId: string,
    latestReviewAt: string, //"2024-07-25T06:05:23"
    providerSource: 'E' | 'A',
    totalRating: number,
    totalRatingCount: number,
    totalRepliedCount: number,
    totalReviewCount?: number,
    totalWaitReplyCount: number,
    updateAt: string, //"2024-07-25T06:35:00"
};

export interface IDisputeSubmitInput {
    reviewId: number,
    disputeReasonDetail: string,
    disputeReasonType: string,
    disputeSupportFile: Record<string, string>,
}
