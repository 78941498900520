// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__module--BCu_G {
  background: #fff;
  height: auto;
}
.s-module__module--BCu_G .s-module__row--sD0ji {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;
}
.s-module__module--BCu_G .s-module__row--sD0ji .s-module__displayItem--fDM24 {
  margin-bottom: 12px;
}
.s-module__module--BCu_G .s-module__row--sD0ji .s-module__displayItem--fDM24 .s-module__label--R7mGE {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__module--BCu_G .s-module__rowItem--UjUQb {
  margin-right: 36px;
}
.s-module__module--BCu_G .s-module__colItem--wHNFB {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/ProviderManagement/components/EditFaqModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;AAHA;EAKI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;AATA;EAeM,mBAAA;AAHN;AAZA;EAWQ,cAAA;EACA,eAAA;EACA,iBAAA;AAIR;AAjBA;EAoBI,kBAAA;AAAJ;AApBA;EAwBI,aAAA;EACA,sBAAA;AADJ","sourcesContent":[".module {\n  background: #fff;\n  height: auto;\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    margin-top: 16px;\n    .displayItem{\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"module": `s-module__module--BCu_G`,
	"row": `s-module__row--sD0ji`,
	"displayItem": `s-module__displayItem--fDM24`,
	"label": `s-module__label--R7mGE`,
	"rowItem": `s-module__rowItem--UjUQb`,
	"colItem": `s-module__colItem--wHNFB`
};
export default ___CSS_LOADER_EXPORT___;
